document.addEventListener("turbolinks:load", function() {
  var submit = document.getElementById("submit_registration")
  $("#input_cgu, #input_privacy, #input_data").on("change", function(e) {
    if ($("#input_cgu, #input_privacy, #input_data").filter(":checked").length == 3) {
      submit.disabled = false;
      submit.style.opacity = "1";
    } else {
      submit.disabled = true;
      submit.style.opacity = "0.5";
    }
  });
})
